import { render, staticRenderFns } from "./OnboardingPlans.vue?vue&type=template&id=49cd7a6e&scoped=true"
import script from "./OnboardingPlans.vue?vue&type=script&lang=js"
export * from "./OnboardingPlans.vue?vue&type=script&lang=js"
import style0 from "./OnboardingPlans.vue?vue&type=style&index=0&id=49cd7a6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49cd7a6e",
  null
  
)

export default component.exports